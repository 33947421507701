@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~react-bootstrap-typeahead/css/Typeahead.min.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

:root {
  --background-color-warning: #f39c12;
  --light-text-color: #c8fff4;
  --dark-text-color: #03dac6;
}

html {
  height: 100%;
  margin: 0;
}

/** Layout **/
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.layout-special {
  padding-left: 5.8rem !important;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 72px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 72px);
  /*padding-top: 0.5rem;*/
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  font-size: 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar .item-collapsed {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.search-bar {
  margin-left: 5px;
}

.navbar-brand img {
  margin-right: 10px;
}

.nav-link i {
  margin-right: 10px;
}

.link-item {
  text-decoration: none;
}

/** Loader & page **/
.load {
  position: relative;
  margin: 50px auto;
  width: 100px;
  height: 80px;
}

.gear {
  position: absolute;
  z-index: -10;
  width: 40px;
  height: 40px;
  animation: spin 5s infinite;
}

.two {
  left: 40px;
  width: 80px;
  height: 80px;
  animation: spin-reverse 5s infinite;
}

.three {
  top: 45px;
  left: -10px;
  width: 60px;
  height: 60px;
}

@keyframes spin {
  50% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  50% {
    transform: rotate(-360deg);
  }
}

.lil-circle {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
  width: 100px;
  height: 100px;
  opacity: 0.65;
}

.blur-circle {
  position: absolute;
  top: -19px;
  left: -19px;
}

.loading-text {
  color: lightgray;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.loading-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/** Login page **/
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  min-width: 300px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input {
  border-radius: 0;
}

.form-signin input.first {
  margin-bottom: -1px;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.form-signin input.last {
  margin-bottom: 10px;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/** Music Page **/
.music-grid {
  width: 100%;
  height: calc(80vh);
}

.custom-tooltip {
  position: absolute;
  width: 350px;
  height: 100px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p span {
  font-weight: bold;
}

.search-container {
  position: relative;
}

.react-simple-input-clear {
  position: absolute;
  right: 8px;
  top: 0;
  font-size: 22px;
  color: #777;
  border: none;
  background: transparent;
}

.download-grid-button {
  border: none;
  background: none;
  margin: 0;
}

.chart-container {
  width: 100%;
  height: 300px;
}

.image-container img {
  width: 150px;
  height: 150px;
}

.user-data h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.profile-data label {
  font-weight: bold;
}

.custom-borders {
  border: 1px solid rgb(222, 226, 230);
  border-top-color: #fff;
}

.error-summary {
  background-color: #fc727a;
  padding: 10px;
  color: #fff;
  border: 1px solid #ff0000;
  margin-bottom: 10px;
  width: 100%;
}

.error-summary ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

/** 404 **/
.main-404 {
  margin-top: 15%;
}

.st0 {
  fill: #fff;
}
.st2 {
  fill: #5d89af;
}
.st3 {
  fill: #709abf;
}
.st4,
.st6 {
  fill: #fff;
  stroke: #b3dcdf;
  stroke-miterlimit: 10;
}
.st6 {
  stroke: #5d89af;
  stroke-width: 2;
}
.st7,
.st8,
.st9 {
  stroke: #709abf;
  stroke-miterlimit: 10;
}

.st7 {
  stroke-width: 5;
  stroke-linecap: round;
  fill: none;
}
.st8,
.st9 {
  fill: #fff;
}
.st9 {
  fill: none;
}
.st10 {
}

#cloud1 {
  animation: cloud003 15s linear infinite;
}

#cloud2 {
  animation: cloud002 25s linear infinite;
}

#cloud3 {
  animation: cloud003 20s linear infinite;
}

#cloud4 {
  animation: float 4s linear infinite;
}

#cloud5 {
  animation: float 8s linear infinite;
}

#tracks {
  animation: slide 650ms linear infinite;
}

#bumps {
  animation: land 10000ms linear infinite;
}

@keyframes jig {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

#carLayers {
  animation: jig 0.35s linear infinite;
}

@keyframes land {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1000px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100px);
  }
}

@keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }
  50% {
    transform: translateY(8px) translateX(5px);
  }
  100% {
    transform: translateY(0px) translateX(0);
  }
}

#bracefront,
#braceback {
  animation: braces 1s linear infinite;
}

@keyframes braces {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-2px);
  }
}

.home {
  position: absolute;
  top: 0;
  left: 5px;
  color: #007bff;
}

/** 500 **/
.error-body {
  height: 100%;
  margin: 0;
  background: linear-gradient(#111, #333, #111) no-repeat;
  background-size: cover;
  color: #eee;
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.eh1,
.eh2,
.eh3 {
  margin: 0;
  line-height: 0.8;
}

.eh2,
.eh3 {
  font-weight: 300;
  color: var(--light-text-color);
}

.eh1 {
  font-weight: 700;
  color: var(--dark-text-color);
  font-size: 8em;
}

.eh2 {
  margin: 30px 0;
}

.eh3 {
  font-size: 2.5em;
}

.back-button {
  background: transparent;
  border: 2px solid var(--light-text-color);
  color: var(--light-text-color);
  padding: 5px 15px;
  font-size: 1.25em;
  transition: all 0.15s ease;
  border-radius: 3px;
}

.back-button:hover {
  background: var(--dark-text-color);
  border: 2px solid var(--dark-text-color);
  color: #111;
  cursor: pointer;
  transform: scale(1.05);
  text-decoration: none;
}

/** 403 **/
.cage {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  background: repeating-linear-gradient(90deg, transparent, transparent 80px, #6f7376 80px, #181617 100px);
  z-index: 2;
  animation: close 3s linear;
}
.access-h1 {
  position: fixed;
  z-index: 1;
  font-size: 23em;
  color: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background-color: #e4e4e1;
  background-image: radial-gradient(at top center, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%),
    linear-gradient(to top, rgba(255, 255, 255, 0.1) 0%, rgba(143, 152, 157, 0.6) 100%);
  background-blend-mode: normal, multiply;
}
.access-h1 span {
  position: relative;
}
.access-h1 span:after {
  content: '403';
  position: absolute;
  top: 0;
  bottom: -96px;
  left: 40px;
  transform: scaleY(0.6) rotateX(-75deg) skewX(-10deg);
  transform-origin: 50% 100%;
  opacity: 0.2;
  line-height: 1;
}
@keyframes close {
  0% {
    left: -75%;
  }
  100% {
    left: 0;
  }
}
