:root {
  --navbar-height: 72px;
  --title-height: 71px;
  --link-color: #3c8dbc;
  --link-hover-color: #72afd2;
  --link-disabled-color: #ccc;
}

button:disabled {
  cursor: not-allowed !important;
}

.reha-modal-primary {
  color: white;
  background-color: #3c8dbc;
}

.reha-modal-warning {
  background-color: #f39c12;
}

.reha-confirm-icon {
  text-align: center;
  vertical-align: middle;
  color: #f39c12;
  font-size: 68px;
}

.reha-event {
  opacity: 0.8;
  color: white;
}

.reha-event-details {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
  position: relative;
}

.reha-event-details > * {
  flex: 1 auto;
}

.reha-event-details strong {
  flex-grow: 2;
}

.reha-event-details span {
  flex-grow: 1;
}

.reha-event-delete {
  position: absolute;
  left: calc(50% - 25px);
  bottom: 10px;
}

.reha-sticky-top {
  position: sticky;
  top: var(--navbar-height);
  z-index: 999;
  background-color: white;
}

.rbc-month-view {
  height: calc(93vh - var(--navbar-height) - var(--title-height));
}

.rbc-event-content {
  padding-left: 5px;
}

.flag-icon {
  height: 24px;
}

.reha-tabs .nav-item {
  cursor: pointer;
}

.reha-tabs .nav-item .active {
  cursor: default;
}

.group-row {
  vertical-align: middle;
}

.required {
  font-weight: bold;
}

.required:after {
  content: ' *';
  color: red;
}

.counter {
  float: left;
}

.counter:after {
  clear: both;
}

.single-grid-on-page {
  width: 100%;
  height: calc(80vh);
}

.custom-tooltip {
  position: absolute;
  width: 350px;
  height: 100px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.year-input-box {
  width: 100px !important;
}

.full-size-modal {
  height: calc(100% - 3.5rem);
}

.scrollable-modal {
  max-height: 70vh;
  overflow-y: auto;
}

.rbt-close {
  border: none;
  background: none;
}

.fc .fc-daygrid-day.fc-day {
  border: 1px solid #cccccc;
}
.fc .fc-col-header-cell.fc-day {
  border-bottom: 1px double #cccccc;
}
.fc .fc-timegrid-col.fc-day {
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
}
.ag-cell {
  display: flex;
  align-items: center;
}
